import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LoginForm from "./components/LoginForm";
import RegisterForm from "./components/RegisterForm";

import { ContainerAccount, Title } from "./style.js";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import api from "../../services/api";
import { verifyLogin } from "./../../utils/verifyLogin";

const Home = () => {
  document.title = "Conta | Elite Comandos";

  const [isLogin, setIsLogin] = useState(false);
  const [dataUser, setDataUser] = useState({});
  const [controllFP, setControllFP] = useState(false);
  const [clearForm, setClearForm] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const isVerified = verifyLogin();
      setIsLogin(isVerified);

      if (isVerified) {
        const dataUserLS = JSON.parse(localStorage.getItem('userDataLogin'));
        setDataUser(dataUserLS);
        setClearForm(false); // Manter os dados do formulário ao logar
      } else {
        setClearForm(true); // Limpar o formulário ao deslogar
      }
    };

    fetchData();
  }, [isLogin]);

  const handleRegister = async (data) => {
    try {
      const response = await api.post("create_client", data);
      if (response.data.success) {
        toast.success(response.data.message);
      } else {
        toast.warning(response.data.message);
      }
    } catch (err) {
      toast.error('Erro, tente novamente mais tarde!');
    }
  };

  const handleUpdate = async (data) => {
    try {
      const response = await api.put("update_client", data);
      if (response.data.success) {
        toast.success(response.data.message);
      } else {
        toast.warning(response.data.message);
      }
    } catch (err) {
      toast.error('Erro, tente novamente mais tarde!');
    }
  };

  const handleLogin = async (data) => {
    try {
      const userData = {
        DS_EMAIL: data.email,
        DS_SENHA: data.password,
      };

      const response = await api.post("clint_login", userData);
      if (response?.data?.TOKEN) {
        toast.success('Logado com sucesso');
        localStorage.setItem('TOKEN', response.data['TOKEN']);
        localStorage.setItem('userDataLogin', JSON.stringify(response.data));
        setIsLogin(true);

        // Preencher os dados do usuário no formulário de edição
        setDataUser({
          nameRegister: response.data.NM_CLIENTE || "",
          nameSocialRegister: response.data.NM_FANTASIA || "",
          nrCPFCNPJRegister: response.data.NR_CPFCNPJ || "",
          IERegister: response.data.NR_IE || "",
          emailRegister: response.data.DS_EMAIL || "",
          email2Register: response.data.DS_EMAIL2 || "",
          nmContact: response.data.NM_CONTATO || "",
          nrPhone: response.data.NR_FONE1 || "",
          WhatsPhone: response.data.IN_WHATSAPP1 || "N",
          nrPhone2: response.data.NR_FONE2 || "",
          WhatsPhone2: response.data.IN_WHATSAPP2 || "N",
          addressName: "", // Preencher com valor padrão ou buscar de outra API
          street: "", // Preencher com valor padrão ou buscar de outra API
          buildingNumber: "", // Preencher com valor padrão ou buscar de outra API
          neighborhood: "", // Preencher com valor padrão ou buscar de outra API
          city: "", // Preencher com valor padrão ou buscar de outra API
          zipCode: "", // Preencher com valor padrão ou buscar de outra API
          contact: "", // Preencher com valor padrão ou buscar de outra API
        });
      } else {
        toast.error('Erro, tente novamente mais tarde!');
      }
    } catch (err) {
      toast.error(err.response?.data?.message || 'Erro, tente novamente mais tarde!');
    }
  };

  const logoutUser = () => {
    localStorage.removeItem('userDataLogin');
    localStorage.removeItem('TOKEN');
    setIsLogin(false);
  };

  return (
    <>
      <Header />
      <ContainerAccount>
        <Container className="conShadown">
          {!isLogin ? (
            <Row>
              <Col xs={12} md={6} className="loginDiv">
                <Title>{controllFP ? "Recuperar Senha" : "Já sou cadastrado"}</Title>
                <LoginForm
                  controllFP={controllFP}
                  setControllFP={setControllFP}
                  handleLogin={handleLogin} // Passar handleLogin como prop
                />
              </Col>
              <Col xs={12} md={6} className="loginDiv">
                <Title>Ainda não possuo cadastro</Title>
                <RegisterForm onSubmit={handleRegister} clearForm={clearForm} />
              </Col>
            </Row>
          ) : (
            <>
              <Row>
                <Col xs={12} md={6} className="menuUser">
                  <Title>Bem Vindo (a) {dataUser.nameRegister}</Title>
                  <p><Link to="/orders">Meus Pedidos</Link></p>
                  <p><Link to="/address">Endereços</Link></p>
                  <p onClick={logoutUser}><Link to="">Sair</Link></p>
                </Col>
                <Col xs={12} md={6}>
                  <Title>Dados Cadastrais</Title>
                  <RegisterForm isEdit={true} dataUser={dataUser} onSubmit={handleUpdate} clearForm={false} />
                </Col>
              </Row>
            </>
          )}
        </Container>
      </ContainerAccount>
      <Footer />
      <ToastContainer />
    </>
  );
};

export default Home;
